import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import styles from './sidebar.module.scss';

const Sidebar = ({ image, imageAlt, content, children }) => {
  const sidebarClass = classnames(styles.sidebar, {
    [styles.sidebarImage]: image,
    [styles.sidebarContent]: children || content
  });
  return (
    <aside className={sidebarClass}>
      <div className={styles.sidebarWrap}>
        {image && (
          <Img className={styles.img} alt={imageAlt} resolutions={image} />
        )}
        {children || content}
      </div>
    </aside>
  );
};

Sidebar.propTypes = {
  image: PropTypes.object,
  content: PropTypes.node,
  children: PropTypes.node,
  imageAlt: PropTypes.string
};

export default Sidebar;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './event-meta.module.scss';

const EventMeta = ({ venue, series, cost, className }) => {
  const classes = classnames(styles.meta, className);

  return (
    <div className={classes}>
      {series && (
        <p>
          <b>Series:</b> {series}
        </p>
      )}
      {venue && (
        <p>
          <b>Venue:</b> {venue}
        </p>
      )}
      {cost && (
        <p>
          <b>Cost:</b> {cost}
        </p>
      )}
    </div>
  );
};

EventMeta.propTypes = {
  className: PropTypes.string,
  cost: PropTypes.string,
  series: PropTypes.string,
  venue: PropTypes.string
};

export default EventMeta;

import React, {useEffect, useState} from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import DefaultLayout from '../components/default-layout/default-layout';
import ContentWrapper from '../components/content-wrapper/content-wrapper';
import Button from '../components/button/button';
import SEO from '../components/seo/seo';
import Sidebar from '../components/sidebar/sidebar';
import SlideshowSmall from '../components/slideshow-small/slideshow-small';
import { get } from '../helpers/hooks-helpers';

import styles from '../components/forms/forms.module.scss';
import { useCurrentFilter } from '../hooks/use-current-filter';
import WYSIWYG from '../components/wysiwyg/wysiwyg';

const SignUpPage = ({ data }) => {
  const sidebarData = useCurrentFilter(get(data, 'SidebarPromo.relationships.field_promoted_content', []));

  // Create vars for the different error states.
  const [NoEmail, setNoEmail] = useState(false);
  const [NoLastName, setNoLastName] = useState(false);
  const [InvalidEmail, setInvalidEmail] = useState(false);
  const [EmailAddressTooLong, setEmailAddressTooLong] = useState(false);
  const [FirstNameTooLong, setFirstNameTooLong] = useState(false);
  const [LastNameTooLong, setLastNameTooLong] = useState(false);

  // Create one more to let the user know the submission was a success.
  const [success, setSuccess] = useState(false);

  // Create an object of all our setState methods so we can easily
  // dynamically call them.
  const setErrors = {
    setNoEmail,
    setNoLastName,
    setInvalidEmail,
    setEmailAddressTooLong,
    setFirstNameTooLong,
    setLastNameTooLong
  };

  // Pull in all parameters from URL to see if there are any form submission errors.
  useEffect(() => {
    // Only supported by modern browsers and not IE11.
    // We're not supporting IE11 so that's ok.
    if ('URLSearchParams' in window) {
      // Browser supports URLSearchParams.
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      // Grab the ?Error= param.
      const errorMessage = params.get('Error');
      // See if the form has been submitted.
      const submitted = params.get('submitted');

      // We only want to support the following errors:
      const allowedErrors = [
        'NoEmail',
        'NoLastName',
        'InvalidEmail',
        'EmailAddressTooLong',
        'FirstNameTooLong',
        'LastNameTooLong'
      ];

      // Check to see if the param is something that we can show
      // an error message for.
      if (allowedErrors.includes(errorMessage)) {
        // This is a dynamically created function name.
        // Example: If `errorMessage` is `NoEmail`,
        // setErrors[`set${errorMessage}`](true) is the same as
        // setErrors.setNoEmail(true).
        setErrors[`set${errorMessage}`](true);
      }
      // If there are no errors and it's submitted it must have been a success.
      else if (submitted === 'true') {
        setSuccess(true);
      }
    }
  }, [setErrors]);

  const sidebar = (
    <Sidebar>
      <SlideshowSmall data={sidebarData.slice(0, 5)} />
    </Sidebar>
  );
  let content = data.nodePage;
  // Set default value for content in case the route doesn't exist in Drupal.
  if (!content) {
    const title = 'Sign Up';
    const body = 'Subscribe to the official insider email of City Springs. Receive exclusive event updates, newsletters, and ticketing offers including pre-sale access and discounts.';
    content = {
      title,
      path: { alias: '/signup' },
      body: { processed: `<p>${body}</p>` },
      metatag: [
        { attributes: { name: 'title', content: title } },
        { attributes: { name: 'description', content: body } }
      ]
    };
  }
  // Reformat the metatag data from Drupal in a way we can use.
  const metatags = content.metatag.reduce((accumulator, currentVal) => {
    return {
      ...accumulator,
      [currentVal.attributes.name]: currentVal.attributes.content
    };
  }, {});

  return (
    <DefaultLayout data={data}>
      <SEO
        {...metatags}
        pageUrl={content.path.alias}
        siteConfig={data.site.siteMetadata}
      />
      <ContentWrapper full={false} sidebar={sidebar}>
        <div className={styles.formWrapper}>
          <h1 className={styles.title}>{content.title}</h1>
          <WYSIWYG>
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: content.body.processed }}
            />
          </WYSIWYG>

          {/* If this page is being displayed after a submission show a submission message. */}
          {success && <p className={styles.title}>Thank you for signing up!</p>}

          {/* If it _isn't_ show the form. */}
          {!success && <form className={styles.form}
            action={`${ data.site.siteMetadata.spektrixAPI }/${data.site.siteMetadata.spektrixClientName}/website/secure/signup.aspx`}
            method="POST">
            <input type="hidden" name="ReturnUrl" value={`${ data.site.siteMetadata.siteUrl }/signup?submitted=true`} />

            <label className={`${styles.formLabel} ${(FirstNameTooLong ? styles.error : '')}`} htmlFor="FirstName">First name:</label>
            <input id="FirstName" className={styles.formInput} name="FirstName" type="text"/>
            { FirstNameTooLong &&
              <p className={styles.errorMessage}>We&apos;re sorry, your first name is too long.</p> }

            <label className={`${styles.formLabel} ${(NoLastName || LastNameTooLong ? styles.error : '')}`} htmlFor="LastName">* Last name:</label>
            <input required id="LastName" className={styles.formInput} name="LastName" type="text"/>
            { NoLastName &&
              <p className={styles.errorMessage}>Last name is a required field.</p> }
            { LastNameTooLong &&
              <p className={styles.errorMessage}>We&apos;re sorry, your last name is too long.</p> }

            <label className={`${styles.formLabel} ${(NoEmail || InvalidEmail || EmailAddressTooLong ? styles.error : '')}`} htmlFor="Email">* Email:</label>
            <input required id="Email" className={styles.formInput} name="Email" type="email"/>
            { NoEmail &&
              <p className={styles.errorMessage}>Email is a required field.</p> }
            { InvalidEmail &&
              <p className={styles.errorMessage}>Email address invalid.</p> }
            { EmailAddressTooLong &&
              <p className={styles.errorMessage}>We&apos;re sorry, your email address is too long.</p> }

            <Button
              type="submit"
              value="Signup"
              textStyle="upper"
              color="blueLight"
              aria-label="Sign Up"
              className={styles.formButton}
            >
                Sign Up
            </Button>
          </form>}
        </div>
      </ContentWrapper>
    </DefaultLayout>
  );
};

SignUpPage.propTypes = {
  data: PropTypes.object
};

export default SignUpPage;

export const query = graphql`
  query SignUpPageQuery {
    site {
      siteMetadata {
        title
        siteUrl
        spektrixClientName
        spektrixAPI
      }
    }
    SidebarPromo: taxonomyTermPromotion(
      drupal_id: { eq: "4a1f98b8-f191-496d-ac7d-db604fcd6840" }
    ) {
      ...Promoted
    }
    nodePage(path: {alias: {eq: "/signup"}}) {
      title
      path {
        alias
      }
      body {
        processed
      }
      metatag: metatag {
        attributes {
          name
          content
        }
      }
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './event-date.module.scss';

const EventDate = ({ date, className }) => {
  const classes = classnames(styles.date, className);

  // `format` is set in /plugins/gatsby-source-drupal-modified/src/normalize.js.
  if (date.format) {
    return <p className={classes}>{date.format}</p>;
  }
  else {
    return (
      <p className={classes}>
        {date.medium} &nbsp;
        <span aria-hidden="true">&middot;</span>
        &nbsp; {date.time}
      </p>
    );
  }
};

EventDate.propTypes = {
  date: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default EventDate;
